export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Escape Road",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://azgames.io/game/escape-road/",
    domain: "escaperoad.online",
    gaId: "G-CGQ9B4044W",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
